import React from "react";
import "./Projects.css";
import Cards from "./Cards";
function Projects() {
  return (
    <section className="projects container" id="projects">
      
    </section>
  );
}

export default Projects;
