import React from "react";
import Navbar from "../navbar/Navbar";
function Header() {
  return (
    <header id='home'>
      <Navbar />
    </header>
  );
}

export default Header;
